import React from 'react';

import Layout from '../components/Layout';
import placeholder from '../assets/images/bg-white.jpg';

import cupcake1 from '../assets/images/cupcakes-01.jpg';
import cupcake2 from '../assets/images/cupcakes-02.jpg';
import cupcake3 from '../assets/images/cupcakes-03.jpg';
import cupcake4 from '../assets/images/cupcakes-04.jpg';
import cupcake5 from '../assets/images/cupcakes-05.jpg';
import cupcake6 from '../assets/images/cupcakes-06.jpg';
import cupcake7 from '../assets/images/cupcakes-07.jpg';
import cupcake8 from '../assets/images/cupcakes-08.jpg';
import cupcake9 from '../assets/images/cupcakes-09.jpg';
import cupcake10 from '../assets/images/cupcakes-10.jpg';


const IndexPage = () => (
  <Layout activeLink="Cupcakes">
    
    <section>
      <div className="container">
        
        <div className="row">
          
          {/* cupcake 1 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake1}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Strawberry Frappe</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* cupcake 2 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake2}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Birthday Set</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* cupcake 3 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake3}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Baby Shower Theme</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* cupcake 4 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake4}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Blue Swirl</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
          {/* cupcake 5 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake5}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Mini Swirl & Hydrangea</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
           {/* cupcake 6 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake6}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Mixed Toppings with White Choc Drizzle</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* cupcake 7 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake7}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Hello Kitty Birthday Theme</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

          {/* cupcake 8 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img custom-img2 mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake8}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Chocolate Drizzle with Cookie & Cream Wafer</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

           {/* cupcake 9 */}
           <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake9}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Chocolate with Fresh Strawberry</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
        </div>
      </div>
    </section> 

    <section>
      <div className="container">
        
        <div className="row">
          
          {/* cupcake 10 */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={cupcake10}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Classic Vanilla with Sprinkles & Cream Wafer</h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              <a href="/contact/" className="card-link">Enquire Now</a>
              {/* <a href="/contact/" className="btn btn-outline-danger hvr-outline-in">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

          {/* Placeholder */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={placeholder}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title"> </h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              {/* <a href="/contact/" className="card-link">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>

          {/* Placeholder */}
          <div className="col-sm card">
            <div class="wrapper">
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={placeholder}
                alt=""
              />
            </div>
            <div class="card-body">
              <h5 class="card-title"> </h5>
              {/* <h6 class="card-subtitle mb-2 text-muted">Cupcakes</h6>
              <p class="card-text">Awesome cupcakes.</p> */}
              {/* <a href="/contact/" className="card-link">Enquire Now</a> */}
              {/* <a href="/contact/" className="card-link">Another link</a> */}
            </div>
          </div>
          
        </div>
      </div>
    </section> 


    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="text-center rounded">
              <section>
                <header>
                  {/* <h3>Table</h3> */}
                </header>
                <div className="table-wrapper">
                  <table className="default">
                    <thead>
                      <tr>
                        <th><h1><a href="https://www.facebook.com/Designer-sweet-treats-144017559724157/?tn-str=k*F" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square social"></i></a></h1></th>
                        <th><h1><a href="https://www.instagram.com/designer_sweet_treats/?hl=en" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram social"></i></a></h1></th>
                        <th><h1><a href="mailto:jeeranan@designersweettreats.net?subject=New%20Enquiry&body=Please%20tell%20us%20the%20following%20information%3A%0D%0A%0D%0AName%3A%20%0D%0AEvent%20Date%3A%20%0D%0AFlavours%3A%0D%0ANumber%20of%20Servings%3A%0D%0ACake%20Design%3A%0D%0AContact%20Number%3A%0D%0A%0D%0AIf%20possible%20please%20also%20attach%20a%20sketch%20or%20photo%20of%20the%20cake%20you%20would%20like." target="_top"><i class="far fa-envelope social"></i></a></h1></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
